export interface ModificationTableManualBlock {
	_id: string;
	name: ModificationTableBlockType;
	rus_name: string;
}

export const ModificationTableBlockType = {
  All: 'all',
  Plan: 'plan',
  Nothing: 'nothing'
} as const;

export type ModificationTableBlockType =
  (typeof ModificationTableBlockType)[keyof typeof ModificationTableBlockType];
